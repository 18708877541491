// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const environment = process.env.ENVIRONMENT || window.dpdEnv;

if (environment !== 'local') {
  Sentry.init({
    environment: environment,
    dsn:
      'https://d705550eda264e72863180b978a9bd18@o645306.ingest.sentry.io/5892997',
    debug: environment !== 'production',
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
